<template>
    <div>
        <b-overlay
            :show="isLoading"
            rounded
            opacity="0.6"
            spinner-variant="primary"
            spinner-mediam
        >
            <transition
                :name="$store.state.appConfig.layout.routerTransition"
                mode="out-in"
            >
                <b-row>
                    <b-col md="9">
                        <b-card 
                            v-if="Object.keys(mhd_product).length > 0" 
                            :title="$t('Checkout')"
                        >
                            <b-card-body>
                                <b-form @submit.prevent="checkout">
                                    <h4 class="text-primary">{{$t('Items')}}</h4>
                                    <div class="text-left">
                                        {{mhd_product.name}}
                                        <p v-if="Object.keys(product_option).length > 0">
                                            {{product_option.name}} - {{product_option.price}} {{$t('SAR')}}
                                        </p>
                                        <p v-else>
                                            {{mhd_product.price}} {{$t('SAR')}}
                                        </p>
                                        <hr>
                                        <div class="text-right">
                                            <dl class="row">
                                                <dt class="col-sm-10 font-weight-bolder">
                                                {{$t('Total Items')}}
                                                </dt>
                                                <dd class="col-sm-2">
                                                {{totals.total_items}} {{$t('SAR')}}
                                                </dd>
                                            </dl>
                                            <dl class="row" v-if="totals.discount > 0">
                                                <dt class="col-sm-10 font-weight-bolder">
                                                {{$t('Discount')}}
                                                </dt>
                                                <dd class="col-sm-2">
                                                {{totals.discount}} {{$t('SAR')}}
                                                </dd>
                                            </dl>
                                            <dl class="row">
                                                <dt class="col-sm-10 font-weight-bolder">
                                                {{$t('VAT')}}
                                                </dt>
                                                <dd class="col-sm-2">
                                                {{totals.vat}} {{$t('SAR')}}
                                                </dd>
                                            </dl>
                                            <dl class="row text-primary">
                                                <dt class="col-sm-10 font-weight-bolder">
                                                {{$t('Final Total')}}
                                                </dt>
                                                <dd class="col-sm-2">
                                                {{totals.final_total}} {{$t('SAR')}}
                                                </dd>
                                            </dl>
                                        </div>
                                    </div>
                                    
                                    <!-- submit -->
                                    <b-col cols="12" class="text-right p-0">
                                        <b-button
                                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                        type="submit"
                                        variant="primary"
                                        >
                                            {{$t('Checkout & Go Pay')}}
                                            <template v-if="isLoadingSave">
                                                <b-spinner small />
                                            </template>
                                        </b-button>
                                    </b-col>
                                </b-form>
                            </b-card-body>
                        </b-card>
                    </b-col>
                </b-row>
            </transition>
        </b-overlay>
    </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/useJwt'
export default {
    directives: {
        Ripple,
    },
    mounted() {
        this.getResults()
    },
    data() {
        return {
            mhd_product: {},
            product_option: {},
            totals: {},
            isLoading: true,
            isLoadingSave: false
        }
    },
    methods: {
        getResults() {
            useJwt.post('/mhd-products/get-by-id',{id: this.$route.params.product_id})
            .then((response) => {
                console.log(response.data)
                this.mhd_product = response.data.data

                // calc totals
                this.totals = {
                    total_items: this.mhd_product.price,
                    vat: this.mhd_product.price * 0.15,
                }
                this.totals.final_total = this.totals.total_items + this.totals.vat;
                
                // has option
                if (this.$route.params.product_option_id) {
                    this.product_option = this.mhd_product.options.filter(option => option.id == this.$route.params.product_option_id)[0];
                    this.totals = {
                        total_items: this.product_option.price,
                        vat: this.product_option.price * 0.15
                    }
                    this.totals.final_total = this.totals.total_items + this.totals.vat;
                }
                this.isLoading = false
            })
            .catch(response => {
                // console.log(response);
            });
        },
        checkout() {
            let form = {
                store_id: this.$store.state.store.id,
                mhd_product_id: this.mhd_product.id,
                mhd_product_option_id: Object.keys(this.product_option).length ? this.product_option.id : null
            }
            
            this.isLoadingSave = true;
            useJwt.post('/mhd-orders/buy',form)
            .then((response) => {
                // console.log(response.data)
                // this.mhd_product = response.data.data

                this.$router.push({ name: 'mhd-store-pay', params: {mhd_order_id: response.data.data} })

                this.isLoadingSave = false
            })
            .catch(response => {
                this.isLoadingSave = false
                // console.log(response);
            });
        }
    },
}
</script>

<style lang="sass" scoped>

</style>
